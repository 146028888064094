@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import 'tailwindcss/utilities';

.my-node-enter {
  max-height: 0;
  overflow: hidden;
  transition: max-height 400ms linear;
  will-change: max-height;
}

.my-node-enter-active {
  max-height: 500px; 
}

.my-node-exit {
  max-height: 500px; 
  overflow: hidden;
  transition: max-height 400ms linear;
  will-change: max-height;
}

.my-node-exit-active {
  max-height: 0;
}

.font-custom::-webkit-scrollbar {
  width: 8px;
}

.font-custom::-webkit-scrollbar-thumb {
  background-color: #B7950B;
}

.bg-animated {
  position: relative;
  z-index: 0;
  background-size: 300% 300%;
  background-image: linear-gradient(45deg, #0b3d0b, #1a1a40, #0b3d0b, #1a1a40);
  animation: gradientAnimation 30s ease infinite;
}

.bg-animated::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: url('https://www.transparenttextures.com/patterns/cartographer.png');
    pointer-events: none;
    z-index: -1;
    mix-blend-mode: overlay;
}

@keyframes gradientAnimation {
    0%, 100% {
        background-position: 100% 50%;
    }
    25% {
        background-position: 0% 20%;
    }
    50% {
        background-position: 50% 75%;
    }
    75% {
        background-position: 0% 45%;
    }
}
